import { HttpClient ,HttpParams,HttpParameterCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Global } from '../global-constant';
import { bannerapiService } from '../home/bannerapi.service';
import { Router,NavigationExtras } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class CatlogService {

  baseurl =Global.baseurl;
  //cartS`ubject = new Subject<any>();
  constructor(private http:HttpClient,private router:Router) { }

  getDynamicMaxRange(){
  return this.http.get(`${this.baseurl}/getDynamicMaxRange`,{responseType:"json"})
  }
  getModificationYearByModelId(modelid:number){
    return this.http.get(`${this.baseurl}/getModificationYearByModelId/`+modelid)
  }

  /* gettrendproductdata(){
    return this.http.get(`${this.baseurl}/getTrendingProducts`,{responseType:"json"})
  } */
  
  getproductbycategory(CategoryId : number){
    //var Subcategory: any = new FormData();
    //Subcategory.append("SubcategoryId", SubcategoryId);
  //  return this.http.post(`${this.baseurl}/get_a_data_join_parent_api_final/part/category/`,+CategoryId)
    return this.http.get(`${this.baseurl}/get_a_data_join_parent_api_final/part/category/`+CategoryId)
  }

  //change above api for iscart 20/08/2021
  //getPartDataByCategoryIdWithCartData

  getPartDataByCategoryIdWithCartData(CustomerId : string,CategoryId:string){

    var subcategory: any = new FormData();
    subcategory.append("CustomerId", CustomerId);
    subcategory.append("CategoryId", CategoryId);
    return this.http.post(`${this.baseurl}/getPartDataByCategoryIdWithCartData `,subcategory)

  }
  getpartbycategoryandmodelno(CategoryId : number,ModificationModelNo : string){
    //var Subcategory: any = new FormData();
    //Subcategory.append("SubcategoryId", SubcategoryId);
  //  return this.http.post(`${this.baseurl}/get_a_data_join_parent_api_final/part/category/`,+CategoryId)
    return this.http.get(`${this.baseurl}/get_a_data_join_parent_api_final_New/part/category/`+CategoryId+"/"+ModificationModelNo)
  }

  getallparts(){
    //var Subcategory: any = new FormData();
    //Subcategory.append("SubcategoryId", SubcategoryId);
  //  return this.http.post(`${this.baseurl}/get_a_data_join_parent_api_final/part/category/`,+CategoryId)
    return this.http.get(`${this.baseurl}/get_all_data_join_api_final/part`)
  }

  getAllProductDataWithCartData(CustomerId:string){
    var cid: any = new FormData();
    cid.append("CustomerId", CustomerId );
  //  return this.http.post(`${this.baseurl}/get_a_data_join_parent_api_final/part/category/`,+CategoryId)
    return this.http.post(`${this.baseurl}/getAllProductDataWithCartData`,cid)
  }

 /*  getBrandDataV2(){
    return this.http.get(`${this.baseurl}/getBrandDataV2`,{responseType:"json"})
  } */
  getBrandDataV3(ModelId : string,ModificationId:string,CategoryId:string,SubcategoryId:string){

    var subcategory: any = new FormData();
    subcategory.append("ModelId", ModelId );
    subcategory.append("ModificationId",ModificationId);
    subcategory.append("CategoryId",CategoryId);
    subcategory.append("SubcategoryId",SubcategoryId);
    return this.http.post(`${this.baseurl}/getBrandDataV5`,subcategory)

  }
  getbrands(){
    return this.http.get(`${this.baseurl}/get_all_data_api_final/brand`,{responseType:"json"})
  }
  getpartsbybrand(selectedItem:any){
    
    return this.http.get(`${this.baseurl}/Brandsfetch/`+selectedItem)
  }

  getallcategory(){
    return this.http.get(`${this.baseurl}/getAllCategoryData`,{responseType:"json"})
  }

 /*  getsubcategorydata(){

    //var subcategory: any = new FormData();
   // subcategory.append("CategoryId", CategoryId);
    return this.http.get(`${this.baseurl}/get_all_data_api_final/subcategory`,{responseType:"json"});

  } */

  getsubcategorydata(CategoryId: number){

    var subcategory: any = new FormData();
    subcategory.append("CategoryId", CategoryId);
    return this.http.post(`${this.baseurl}/getSubCategoryByCategoryId`,subcategory)

  }
  getproductbybrand(BrandId : number){
  
    return this.http.get(`${this.baseurl}/get_a_data_join_parent_api_final/part/brand/`+BrandId)
  }

 

  /* getCategoryDataWithSubcategoryData(){
    //var subcategory: any = new FormData();
   // subcategory.append("CategoryId", CategoryId);
    return this.http.post(`${this.baseurl}/getCategoryDataWithSubcategoryDatav2 `,{responseType:"json"})
  } */

  getCategoryDataWithSubcategoryData(ModelId :string,ModificationId:string){
    var partbybrandid: any = new FormData();
    partbybrandid.append("ModelId", ModelId );
    partbybrandid.append("ModificationId",ModificationId)
    return this.http.post(`${this.baseurl}/getCategoryDataWithSubcategoryDataV5 `,partbybrandid) 
  }

  //category data with brand
  getCategoryDataWithSubcategoryDataV6(ModelId :string,ModificationId:string,BrandId :any){
    var partbybrandid: any = new FormData();
    partbybrandid.append("ModelId", ModelId );
    partbybrandid.append("ModificationId",ModificationId);
    partbybrandid.append("BrandId",BrandId);
    return this.http.post(`${this.baseurl}/getCategoryDataWithSubcategoryDataV6`,partbybrandid) 
  }

  cartDetails(CustomerId: string){

    var addtocart: any = new FormData();
    addtocart.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/getCart`,addtocart);

  }

  /* getmodificationdata(BrandId:number,subcategoryId : number){
    var bid = BrandId;
    var subcatid = subcategoryId;
    //var Subcategory: any = new FormData();
    //Subcategory.append("SubcategoryId", SubcategoryId);
    return this.http.get(`${this.baseurl}/Filter?subcategoryId='"+subcatid+"'&BrandId=/`+subcatid)
  } */


  getPartDataBySubcategoryId(CustomerId: string,SubcategoryId :string){
    var filterscid: any = new FormData();
    filterscid.append("CustomerId", CustomerId );
    filterscid.append("SubcategoryId", SubcategoryId );
    return this.http.post(`${this.baseurl}/getPartDataBySubcategoryId`,filterscid);
  }

  filter(BrandId:any,MinPrice :any,MaxPrice :any,CustomerId:any ){
     let params = new HttpParams();
    if(BrandId !=''){
      params = params.append('BrandId',BrandId);
    }
    
    if(MinPrice != ''){
      params = params.append('MinPrice',MinPrice);
    }
    if(MaxPrice !=''){
      params = params.append('MaxPrice',MaxPrice);
    }
    
      params = params.append('CustomerId',CustomerId ); 
    

    
      let URL;
      let URLElement;
      let URLElement2;
      let BrandElement;
      URL=this.router.url.split('/');
      URLElement=URL[2]; 
      let searchurl = URL[2]; 
      BrandElement =URL[2];
      URLElement2=URL[3];
      console.log("URL Element"+URLElement);
      console.log("URL Element 2"+URLElement2);
      let a = encodeURI(URL)
  
    return this.http.get(`${this.baseurl}/Filter`,{params: params});
  }

  filterv2(u1:string){
    /* let params = new HttpParams();
    if(BrandId !=''){
      params = params.append('BrandId',BrandId);
    }
    
    if(MinPrice != ''){
      params = params.append('MinPrice',MinPrice);
    }
    if(MaxPrice !=''){
      params = params.append('MaxPrice',MaxPrice);
    }
    
      params = params.append('CustomerId',CustomerId );  */
    

    
      /* let URL;
      let URLElement;
      let URLElement2;
      let BrandElement;
      URL=this.router.url.split('/');
      URLElement=URL[2]; 
      let searchurl = URL[2]; 
      BrandElement =URL[2];
      URLElement2=URL[3];
      console.log("URL Element"+URLElement);
      console.log("URL Element 2"+URLElement2);
      let a = encodeURI(URL)
   */
   /*  return this.http.get(`${this.baseurl}/FilterV2?`+u1); */
  //old FilterV3WithSortingTesting
   return this.http.get(`${this.baseurl}/FilterV3WithSortingTestingV3?`+u1);
  }

  /* filter(url1:string){
   /*  let params = new HttpParams();
   if(BrandId !=''){
     params = params.append('BrandId',BrandId);
   }
   
   if(MinPrice != ''){
     params = params.append('MinPrice',MinPrice);
   }
   if(MaxPrice !=''){
     params = params.append('MaxPrice',MaxPrice);
   }
   
     params = params.append('CustomerId',CustomerId ); 
    */

   
     /* let URL;
     let URLElement;
     let URLElement2;
     let BrandElement;
     URL=this.router.url.split('/');
     URLElement=URL[2]; 
     let searchurl = URL[2]; 
     BrandElement =URL[2];
     URLElement2=URL[3];
     console.log("URL Element"+URLElement);
     console.log("URL Element 2"+URLElement2);
     let url1 = encodeURI(URL) 
 
   return this.http.get(`${this.baseurl}/Filter/`+url1);
 } */


  searchByFormWithPartData (ModelId:number,ModificationId : number){
    //var Subcategory: any = new FormData();
    //Subcategory.append("SubcategoryId", SubcategoryId);
    return this.http.get(`${this.baseurl}/searchByFormWithPartData/modification/`+ModelId+"/"+ModificationId)
  }

  navsearch(ProductName : string){

    var serachdata: any = new FormData();
    serachdata.append("ProductName", ProductName);
    return this.http.get(`${this.baseurl}/searchNew`,serachdata)
  }
  getPartDataByBrandId(CustomerId:string,BrandId:string){
    var partbybrandid: any = new FormData();
    partbybrandid.append("CustomerId", CustomerId);
    partbybrandid.append("BrandId",BrandId)
    return this.http.post(`${this.baseurl}/getPartDataByBrandId`,partbybrandid)
  }

  getProductByOfferCode (CustomerId:string,PartOfferCode:string){
    var partbybrandid: any = new FormData();
    partbybrandid.append("CustomerId", CustomerId);
    partbybrandid.append("PartOfferCode",PartOfferCode)
    return this.http.post(`${this.baseurl}/getProductByOfferCode`,partbybrandid)
  }

  getModificationDataByModelIdOrModificationId(ModelId :string,ModificationId:string){
    var partbybrandid: any = new FormData();
    partbybrandid.append("ModelId", ModelId);
    partbybrandid.append("ModificationId",ModificationId)
    return this.http.post(`${this.baseurl}/getModificationDataByModelIdOrModificationId `,partbybrandid)
  }
  loginapi(mobileno :string,password:string){
     var userData: any = new FormData();
     userData.append("CustomerPhoneNo", mobileno);
     userData.append("CustomerPassword", password);

    return this.http.post(`${this.baseurl}/login_api`, userData);
  }
  getCartByCustomerIdAndProductId(CustomerId:string,PartId:string){
    var partbybrandid: any = new FormData();
    partbybrandid.append("CustomerId", CustomerId);
    partbybrandid.append("PartId", PartId)
    return this.http.post(`${this.baseurl}/getCartByCustomerIdAndProductId`,partbybrandid)
  }
  

 
}
