import { Component, OnInit, TemplateRef,Output,EventEmitter,Input } from '@angular/core';
import { PlaceorderService } from '../placeorder/placeorder.service';

import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Customer } from '../placeorder/placeorder';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ViewChild } from '@angular/core';
//import { ProfileComponent } from '../profile/profile.component';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  fname;
  cname;
  address;
  city;
  pincode;
  mobno;
  email;
  state;
  country;

  
  addaddressform: FormGroup;
  Updateaid: any;
  aid;
  closeResult='';
  alladdress;
  addName: any;
  addid: any;
  alladd: any;
  FirstName;
  CompanyName;
  Address;
  City;
  Pincode; 
  Mobileno;
  Email;
  State: any;
  Country: any;
  defaddress;
  defaddressid: any;
  @Output() parentfunction:EventEmitter<any>= new EventEmitter();
//@Input() parentfunction;

  @ViewChild('addadress', { static: true }) addadressmodal: TemplateRef<any>;
  
  constructor(public modalService: NgbModal,public placeorderservice:PlaceorderService ,public  formbuilder:FormBuilder,public router:Router)  { }
  open(content) {
    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  public getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  
  ngOnInit(): void {
   // this.parentfunction();
    
    this.setFormStateaddaddress();
    this.getdefaddress();

    //get address 03/08/2021
    var customerid = sessionStorage.getItem('CustomerId');
    this.placeorderservice.getAddress(customerid).subscribe((data)=>{
      this.alladdress = data["Data"];
      console.log(this.alladdress);
      for(let i=0;i<this.alladdress.length;i++){
        this.addName = data["Data"][i]["AddressName"];
        this.addid = data["Data"][i]["AddressId"];
       // alert(data["Data"])
       //  this.alladd.push(this.addid);
    
      }
    })

  }
  setFormStateaddaddress(): void{
    //console.log(this.defaddress)
    this.addaddressform = this.formbuilder.group({
      id:[''],
      fullname : ['',[Validators.required]],
      companyname : [''],
      address : ['',[Validators.required]],
      city : ['',[Validators.required]],
      pincode : ['',[Validators.required, Validators.minLength(6)]],
      mobileno : ['',[Validators.required,Validators.minLength(10), Validators.maxLength(12)]],
      /* email : ['',[Validators.required]], */
      state : ['',[Validators.required]],
      country : ['',[Validators.required]],
     /*  state : ['',[Validators.required]],
      country : ['',[Validators.required]] */

    })
  }
  get f() { return this.addaddressform.controls; }
  onSubmitaddaddress(){
  //  alert("hello")
    let addressform = this.addaddressform.value;
    this.addressform(addressform);
    //alert("Submit")
  }
  addressform(addressform : Customer){
    var customerid = sessionStorage.getItem('CustomerId');
    //alert(this.defaddressid)
    if(this.Updateaid !=null){
      this.placeorderservice.updateAddress(this.Updateaid,addressform).subscribe((res)=>{
        
       
        this.refresh();
     //  this.porderdefnull();
    })
    }
    else{
      if(this.addaddressform.value.companyname ==undefined)
      {
        //alert("hi");
        this.addaddressform.value.companyname = '';
      }
      else{
        //alert("heloi");
      }
      this.placeorderservice.postuser(customerid,addressform).subscribe((res)=>{
        let Message=res["Message"];
        let condition=res["IsSuccess"];
        let data=res["Data"];
  
        this.aid= res["Data"][0]["AddressId"];
        console.log(this.aid);
        this.refresh();
     //  this.porderdefnull();
    })
    }
  }
  refresh(): void {
    window.location.reload();
  }
  updateaddress(addid){
    // alert(addid)
     var customerid = sessionStorage.getItem('CustomerId');
     /* this.placeorderservice.getdefaultaddress(customerid).subscribe((data)=>{
       this.defaddress = data["Data"];
      // this.FirstName = "asd;"
      // console.log(this.defaddress)
       //console.log(data["Data"][0]["AddressId"]);
       //get all data
      // if(this.defaddress !=''){
        this.Updateaid =addid;
 
       //this.defaddressid = data["Data"][0]["AddressId"];
       this.FirstName = data["Data"][0]["AddressForName"];
       this.CompanyName = data["Data"][0]["AddressCompanyName"];
       this.Address = data["Data"][0]["AddressName"];
       this.City = data["Data"][0]["AddressCityName"];
       this.Pincode = data["Data"][0]["AddressPincode"];
       this.Mobileno = data["Data"][0]["AddressPhoneNo"];
       this.Email = data["Data"][0]["AddressEmailId"];
       //console.log(this.fname)
      
     //  }
       
 
     })  */
     this.placeorderservice.getAddressByAddressId(addid).subscribe((data)=>{
       this.defaddress = data["Data"];
       // this.FirstName = "asd;"
       // console.log(this.defaddress)
        //console.log(data["Data"][0]["AddressId"]);
        //get all data
       // if(this.defaddress !=''){
         this.Updateaid =addid;
  
        //this.defaddressid = data["Data"][0]["AddressId"];
        this.FirstName = data["Data"][0]["AddressForName"];
        this.CompanyName = data["Data"][0]["AddressCompanyName"];
        this.Address = data["Data"][0]["AddressName"];
        this.City = data["Data"][0]["AddressCityName"];
        this.Pincode = data["Data"][0]["AddressPincode"];
        this.Mobileno = data["Data"][0]["AddressPhoneNo"];
        this.Email = data["Data"][0]["AddressEmailId"];
        this.State = data["Data"][0]["AddressState"];
        this.Country = data["Data"][0]["AddressCountry"];
        //console.log(this.fname)
     })
     
   }
 
   deleteaddress(addid){
     if(confirm("Are you sure to delete address")) {
       console.log("Implement delete functionality here");
       this.placeorderservice.deleteAddress(addid).subscribe((data)=>{
         //alert("delete sucessfully")
         
         this.refresh();
       
       })
 
     }
     
   }
   cancelbutton(){
      // alert("Hii")
     this.addaddressform.reset();
   }
   getdefaddress(){
    var customerid = sessionStorage.getItem('CustomerId');
    this.placeorderservice.getdefaultaddress(customerid).subscribe((data)=>{
      this.defaddress = data["Data"];
    //  this.FirstName = data["Data"][0]["AddressForName"];
     // console.log(this.defaddress)
      //console.log(data["Data"][0]["AddressId"]);
      //get all data
      if(this.defaddress !=''){
        
      this.defaddressid = data["Data"][0]["AddressId"];
      this.fname = data["Data"][0]["AddressForName"];
      this.cname = data["Data"][0]["AddressCompanyName"];
      this.address = data["Data"][0]["AddressName"];
      this.city = data["Data"][0]["AddressCityName"];
      this.pincode = data["Data"][0]["AddressPincode"];
      this.mobno = data["Data"][0]["AddressPhoneNo"];
      this.email = data["Data"][0]["AddressEmailId"];
      this.state = data["Data"][0]["AddressState"];
      this.country = data["Data"][0]["AddressCountry"];

      
      console.log(this.parentfunction.emit())
      console.log("In address.ts"+this.fname)
     
      }
      else{
       //alert("hello")
        this.open(this.addadressmodal);
      }
      

    }) 
   // this.billingform.disable();
   // this.billingform.controls['this.fname'].disable();
    //alert(this.defaddressid)
  }
  changedefaddress(addressid){
    //alert(addressid)
    
    var customerid = sessionStorage.getItem('CustomerId');
    this.placeorderservice.updateDefaultAddress(customerid,addressid).subscribe((data)=>{
      this.defaddress = data["Data"];
     // alert("hello")
      this.getdefaddress();
      this.refresh();
    //  this.parentfunction.emit();
      /* this.loading = true; */
     // this.billingform.disable();
    })
    
  }
  

}
