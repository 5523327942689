import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  baseurl =Global.baseurl;
  vehiclepagedata = new Subject();
  constructor(private http:HttpClient) { }
  

  /* getmodificationdata(ModificationId : number){
    //var Subcategory: any = new FormData();
    //Subcategory.append("SubcategoryId", SubcategoryId);
    return this.http.get(`${this.baseurl}/get_a_data_join_api_final/modification/`+ModificationId)
  } */

  // piyush bcz modelid also pass  24/07/2021
  // getmodificationdata(ModelId:number,ModificationId : number){
  //   //var Subcategory: any = new FormData();
  //   //Subcategory.append("SubcategoryId", SubcategoryId);
  //   //for choose button 
  //   return this.http.get(`${this.baseurl}/get_a_data_join_api_final_Model/modification/`+ModelId+"/"+ModificationId)
  // }
  //new get_a_data_join_api_final_Model_UP_V2
  getmodificationdata(makerid:number ,ModelId:number,ModificationId : number){
    //var Subcategory: any = new FormData();
    //Subcategory.append("SubcategoryId", SubcategoryId);
    //for choose button 
    return this.http.get(`${this.baseurl}/get_a_data_join_api_final_Model_UP_V2/`+makerid+"/"+ModelId+"/"+ModificationId)
  }
  getmodificationdataone(ModelId:number){
    //var Subcategory: any = new FormData();
    //Subcategory.append("SubcategoryId", SubcategoryId);
    return this.http.get(`${this.baseurl}/get_a_data_join_api_final_Model/modification/`+ModelId)
  }
  get_a_data_join_api_final_Model_UP_V2(makerid :string,modelid : string){
    //var Subcategory: any = new FormData();
    //Subcategory.append("SubcategoryId", SubcategoryId);
    return this.http.get(`${this.baseurl}/get_a_data_join_api_final_Model_UP_V2/`+makerid +"/"+modelid)
  }




  
}
