import { Component, OnInit } from '@angular/core';
import { OrderdetailService } from './orderdetail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../global-constant';
import Swal from 'sweetalert2';
import { PlaceorderService } from '../placeorder/placeorder.service';
@Component({
  selector: 'app-orderdetail',
  templateUrl: './orderdetail.component.html',
  styleUrls: ['./orderdetail.component.css']
})
export class OrderdetailComponent implements OnInit {

  orderdetails;
  otherdeatils;
  bannerurl = Global.bannerurl;
  paymentfail;
  ReturnData = null;
  SalesreturnReason: any;
  ReturnStatus: any;
  grandtotal;
  orderid;
  defaddressid;
  customerida;
  deliverycharge;
  CourierId;


  constructor(private api:OrderdetailService,private placeorderservice:PlaceorderService, private router:Router,private route: ActivatedRoute) { }

  ngOnInit(): void {

    let  customerId;
     /*  this.route.queryParams.subscribe(params =>{
       customerId = params["customerId"];
      })
      if(customerId!=null){
        //alert(customerId)
        sessionStorage.setItem('CustomerId',customerId);
        this.router.navigate(['']).then(() => {
          window.location.reload();
        });



      } */

    let URL;
    let URLElement;
    let URLElement3;

    URL=this.router.url.split('/');
    URLElement=URL[2];  
    URLElement3 = URL[3];
    //console.log( localStorage.getItem('subcategoryid'));
      //console.log(StateId);
      //this.checkstock();
      if (URLElement!=null) {
        //alert(URLElement)
        this.api.getorderdetails(URLElement).subscribe((data) => {
         
            this.orderdetails = data["Data"][0]["Orders"];
           /*  alert(this.orderdetails) */
            this.otherdeatils = data["Data"][0]["OtherDetail"];
            this.ReturnData = data["Data"][0]["ReturnData"];
            this.grandtotal = data["Data"][0]["OtherDetail"][0]["Total"];
           this.orderid = data["Data"][0]["Orders"][0]["OrderIdReference"];
           // alert(data["Data"])

           this.defaddressid = data["Data"][0]["OtherDetail"][0]["AddressId"];
           this.CourierId = data["Data"][0]["OtherDetail"][0]["CourierId"];
           this.customerida = data["Data"][0]["OtherDetail"][0]["CustomerId"];
           this.deliverycharge = data["Data"][0]["OtherDetail"][0]["DeliveryCharge"];

           // alert(this.grandtotal) 
          /*  alert(this.grandtotal )
           alert(this.orderid )
           alert(this.defaddressid) */
            if(this.ReturnData != null){
            //  alert("IF ")
              this.SalesreturnReason = data["Data"][0]["ReturnData"][0]["SalesreturnReason"];
            this.ReturnStatus = data["Data"][0]["ReturnData"][0]["ReturnStatus"];
            }
            
             
           // alert(this.orderdetails)
           // this.orderdeatilsstatus = data["Data"][0]["OrderdetailStatus"];
           // console.log(this.otherdeatils);
           
           //alert("Samundar")
           

      });
      } 
      else {
      //  alert("else")
        this.orderdetails = null;
      }
      //alert("Hii")
      if(URLElement3!=null){
        this.paymentfail = true;
      }

     // alert("Hii")
      /* alert(this.grandtotal )
      alert(this.orderid )
      alert(this.defaddressid) */
  }
  openrazorpay(){
   /*  alert(this.grandtotal)
    alert(this.orderid) */
    let optionsFinal=this.getRate(this.grandtotal*100,this.orderid);
    //alert(optionsFinal);
    let rzp1 = new this.placeorderservice.nativeWindow.Razorpay(optionsFinal);
    rzp1.open();
  }
  getRate(rate,oid)
{
  //alert(this.grandtotal)
  let options = {
    //buyparts live api key : rzp_live_eRlcxzms391Pie
    "key": "rzp_test_pSXjLcjx09zdZV", // Enter the Key ID generated from the Dashboard  test mode key rzp_test_pSXjLcjx09zdZV
    "amount":rate,  // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "Buy Parts Online",
    "description": "Test Transaction",
    "image": "https://example.com/your_logo",
    // "order_id": oid,  
     "callback_url": `https://buypartsonline.in/AdminSide/Admin/Ajax/UpdateplaceOrderForBuyPartV2/${this.defaddressid}/${this.customerida}/online/${this.deliverycharge}/${this.CourierId}/1/${this.orderid}`,
    "redirect": true,
    
     //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": function (response){
        alert(response.razorpay_payment_id);
       // alert(response.razorpay_order_id);
        //alert(response.razorpay_signature)

      //this.TransactionId = response.razorpay_payment_id;
      
     
        /* if(response.razorpay_payment_id)
        {
         
        } */

        

    },
    "prefill": {
        "name": sessionStorage.getItem('CustomerName'),
        "email": "",
        "contact": sessionStorage.getItem('CustomerPhoneNo')
    },
    "notes": {
        "address": "Razorpay Corporate Office"
    },
    "theme": {
        "color": "#3399cc"
    }
  };
  return options;
}

}
