import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';
@Injectable({
  providedIn: 'root'
})
export class MyorderService {

  baseurl =Global.baseurl;
  constructor(private http:HttpClient) { }

  getorderhistory(CustomerId: string){

    var orderhistory: any = new FormData();
    orderhistory.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/orderHistory`,orderhistory);

  }

  cancelOrder(OrderId: string,CustomerId:string){

    var orderhistory: any = new FormData();
    orderhistory.append("OrderId", OrderId);
    orderhistory.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/cancelOrder`,orderhistory);

  }
  printBillWebsite(OrderId:string){
    var printbill: any = new FormData();
    printbill.append("OrderId", OrderId);
    return this.http.post(`${this.baseurl}/printBillWebsite`,printbill);
  }
  getSetting(){
    return this.http.get(`${this.baseurl}/getSetting`,{responseType:"json"})
    }
}
