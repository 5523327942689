
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { NgxPaginationModule } from 'ngx-pagination';
import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HomeModule } from './home/home.module';
 import { CategoriesModule } from './categories/categories.module';
 import { LoginComponent } from './login/login.component';

import {HttpClientModule} from '@angular/common/http';
import { AboutComponent } from './about/about.component';
// import { CategoriesComponent } from './categories/categories.component';
 import { ContactusComponent } from './contactus/contactus.component';
//import { SearchbycategoryComponent } from './searchbycategory/searchbycategory.component';
import { ProductsComponent } from './products/products.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { SearchingComponent } from './searching/searching.component';
import { CartComponent } from './cart/cart.component';
import { SearchcategoryModule } from './searchbycategory/searchcategory.module';
//import { VehicleComponent } from './vehicle/vehicle.component';
import { OffersModule } from './offers/offers.module';
//import { CatlogComponent } from './catlog/catlog.component';
import { VehicleModule } from './vehicle/vehicle.module';
import { CatlogModule } from './catlog/catlog.module';
import { BrandscatlogModule } from './brandscatlog/brandscatlog.module';
import { BrandsModule } from './brands/brands.module';
import { ModalexampleComponent } from './modalexample/modalexample.component';
import { PlaceorderComponent } from './placeorder/placeorder.component';
import { MyorderComponent } from './myorder/myorder.component';
import { OrderdetailComponent } from './orderdetail/orderdetail.component';
import { PaymentComponent } from './payment/payment.component';
import { BrandpageComponent } from './brandpage/brandpage.component';
import { CategorypageComponent } from './categorypage/categorypage.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { ReturnpolicyComponent } from './returnpolicy/returnpolicy.component';
import { AddressComponent } from './address/address.component';
import { OtpComponent } from './otp/otp.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ReturnorderComponent } from './returnorder/returnorder.component';
//import { AboutusComponent } from './aboutus/aboutus.component';
//import { BrandscatlogComponent } from './brandscatlog/brandscatlog.component';
//import { BrandsComponent } from './brands/brands.component';
//import { OffersComponent } from './offers/offers.component';
import { DatePipe } from '@angular/common';
import { BillprintComponent } from './billprint/billprint.component';
import { ReturninvoiceComponent } from './returninvoice/returninvoice.component';
//import { JwPaginationComponent } from 'jw-angular-pagination';
import { JwPaginationModule } from 'jw-angular-pagination';

/* import { TestingComponent } from './testing/testing.component' */

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    AboutComponent,
  //  CategoriesComponent,
    ContactusComponent,
   // SearchbycategoryComponent,
    ProductsComponent,
    ProductdetailComponent,
    SearchingComponent,
    CartComponent,
    ModalexampleComponent,
    PlaceorderComponent,
    MyorderComponent,
    OrderdetailComponent,
    PaymentComponent,
    BrandpageComponent,
    CategorypageComponent,
    PrivacypolicyComponent,
    TermsconditionsComponent,
    ReturnpolicyComponent,
    AddressComponent,
    OtpComponent,
    BreadcrumbComponent,
    ReturnorderComponent,
    BillprintComponent,
    ReturninvoiceComponent,
  //  JwPaginationComponent
   /*  TestingComponent, */
    //AboutusComponent,
   // VehicleComponent,
   // CatlogComponent,
  // BrandscatlogComponent,
    //BrandsComponent,
    //OffersComponent,
   
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
    ReactiveFormsModule,
    CategoriesModule, 
    SearchcategoryModule,
    OffersModule,
    VehicleModule,
    CatlogModule,
    BrandscatlogModule,
    BrandsModule,
    NgxPaginationModule
    
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
