import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from '../global-constant';
import { VehicleService } from './vehicle.service';
import { CatlogService } from '../catlog/catlog.service';
@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {

  products : {};
  baseurl;
  bannerurl = Global.bannerurl;
 subcategoryids : {};
 //loader = true;
  loading;
  vehicles;
  modelno: string;
  piyush;
  vehiclepagemodelno:string 
  makerstypename: string;
  makersname: string;
  modelname: string;
  year: string;
  modificationname: string;
  homepagebreadcrumb3: boolean;
  homepagebreadcrumb5: boolean;
  formdata: any;
  model3: any;
  makersid: any;
  modelid: any;
  modificationid: any;
  choosebuttonshow:boolean = true;

  constructor(private api:VehicleService,private router:Router,private route: ActivatedRoute,private api1:CatlogService) { }

  ngOnInit(): void {

    

    /* this.makerstypename = localStorage.getItem('1');;
      this.makersname =localStorage.getItem('2');;
      this.modelname =localStorage.getItem('3');;
      this.year =localStorage.getItem('4');;
      this.modificationname =localStorage.getItem('5');;
      
    //alert(this.year)
      
    if(this.year == 'undefined'){
     // alert("IF")
      this.homepagebreadcrumb3 = true;    
      this.homepagebreadcrumb5 = false;
    }
    else if(this.year == null){
      this.homepagebreadcrumb3 = false;    
      this.homepagebreadcrumb5 = false;
    }
    else if(this.year !=null || this.year !='undefined'){
     // alert("Else If")
      this.homepagebreadcrumb5 = true;
     // this.homepagebreadcrumb3 = false;
    } */
    //this.piyush ="hello"
    //this.api.vehiclepagedata.next(1234);
    this.api.vehiclepagedata.subscribe((res)=>{
      URLElement2 =res;
      //alert(res)
    })
    
  
    //this.baseurl="http://autoparts.sikho99.com";
    let URL;
    let URLElement3;
    let URLElement2
    URL=this.router.url.split('/');
    URLElement2 = URL[2];
    URLElement3=URL[3];  
    this.makersid = URL[2];
    this.modelid = URL[3];
    this.modificationid = URL[4];

      //console.log( localStorage.getItem('subcategoryid'));
      //console.log(StateId);
     // if (URLElement!=null) {
        //this.loader = true;
        if(this.modificationid == null){
          // this.api.getmodificationdataone(URLElement2).subscribe(
          //   data => {this.vehicles = data["Data"];
          //   this.loading =false;
          //   console.log(this.vehicles)
          //   this.modelno = data["Data"][0]["ModificationModelNo"];
          //   console.log(this.modelno)
          //   for(let i=0;i<this.vehicles.length;i++){
          //     this.modelno = data["Data"][i]["ModificationModelNo"];
          //   console.log(this.modelno)
          //   }
          //   });
          if(this.modelid !=null){
            this.api.get_a_data_join_api_final_Model_UP_V2(this.makersid,this.modelid).subscribe((data)=>{
              this.vehicles = data["Data"];
              // this.modelno = data["Data"][0]["ModificationModelNo"];
                for(let i=0;i<data["Data"][i]["Modofication"].length;i++){
                    this.modelno = data["Data"][0]["Modofication"][i]["ModificationModelNo"];
                  console.log(this.modelno)
                 }
              console.log(this.vehicles)
            })
          }
          else{
            this.api.get_a_data_join_api_final_Model_UP_V2(this.makersid,'').subscribe((data)=>{
              this.vehicles = data["Data"];
              for(let i=0;i<data["Data"][i]["Modofication"].length;i++){
                this.modelno = data["Data"][0]["Modofication"][i]["ModificationModelNo"];
              console.log(this.modelno)
             }
              // this.modelno = data["Data"][0]["ModificationModelNo"];
              console.log(this.vehicles)
            })
          }
         
        }
        else{
          this.api.getmodificationdata(this.makersid,this.modelid,this.modificationid).subscribe(
            data => {this.vehicles = data["Data"];
            this.loading =false;
            console.log(this.vehicles)
            // this.modelno = data["Data"][0]["ModificationModelNo"];
            // console.log(this.modelno)
            // for(let i=0;i<this.vehicles.length;i++){
            //   this.modelno = data["Data"][i]["ModificationModelNo"];
            // console.log(this.modelno)
            // }
            for(let i=0;i<data["Data"][i]["Modofication"].length;i++){
              this.modelno = data["Data"][0]["Modofication"][i]["ModificationModelNo"];
            console.log(this.modelno)
           }
            });
        }
      /*   this.api.getmodificationdata(URLElement2,URLElement3).subscribe(
          data => {this.vehicles = data["Data"];
          this.loading =false;
          console.log(this.vehicles)
          this.modelno = data["Data"][0]["ModificationModelNo"];
          console.log(this.modelno)
          for(let i=0;i<this.vehicles.length;i++){
            this.modelno = data["Data"][i]["ModificationModelNo"];
          console.log(this.modelno)
          }
          }); */
        
      /* } else {
        this.vehicles = null;
      } */
      //this.loader = false;
      this.api1.getModificationDataByModelIdOrModificationId(this.modelid,this.modificationid).subscribe((res)=>{
        this.formdata =res["Data"];
        this.makerstypename =res["Data"][0]["MakerstypeName"];
        this.makersname =res["Data"][0]["MakersName"];
        this.modelname =res["Data"][0]["ModelName"];
        this.year =res["Data"][0]["ModificationStartYear"];
        this.modificationname =res["Data"][0]["ModificationName"];
        this.model3 = res["model3"];
        //alert(this.model3)
        console.log(this.makersname)
        this.homepagebreadcrumb5 = true;
        
      //  localStorage.getItem('breadcrumb',this.makerstypename)
      //  alert(this.year)
        if(this.model3 ==1){
          //alert("hello")
       // this.year = '';
          
       // this.model3 = '';
        
        this.homepagebreadcrumb3 = true;    
        this.homepagebreadcrumb5 = false;    
        }
        if(this.year !=null){
         // alert("year")
          this.homepagebreadcrumb5 = true;
          this.homepagebreadcrumb3 = false;
        }
        
        localStorage.setItem('1',this.makerstypename);
      localStorage.setItem('2',this.makersname);
      localStorage.setItem('3',this.modelname);
      localStorage.setItem('4',this.year);
      localStorage.setItem('5',this.modificationname);

        
      })
  }
  choosemodification(ModificationId){
    this.loading = true;
    this.choosebuttonshow  = false;

    this.api.getmodificationdata(this.makersid,this.modelid,ModificationId).subscribe(
      data => {this.vehicles = data["Data"];
      
    this.choosebuttonshow  = true;
      this.loading =false;
      console.log(this.vehicles)
      // this.modelno = data["Data"][0]["ModificationModelNo"];
      // console.log(this.modelno)
      // for(let i=0;i<this.vehicles.length;i++){
      //   this.modelno = data["Data"][i]["ModificationModelNo"];
      // console.log(this.modelno)
      // }
      for(let i=0;i<data["Data"][i]["Modofication"].length;i++){
        this.modelno = data["Data"][0]["Modofication"][i]["ModificationModelNo"];
      console.log(this.modelno)
     }
      }); 
  }

}
