import { Component, Input, OnInit, TemplateRef } from '@angular/core';

import { PlaceorderService } from './placeorder.service';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Customer } from './placeorder';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { alladdressint } from './address.interface';
import { ViewChild } from '@angular/core';
//import { ProfileComponent } from '../profile/profile.component';

@Component({
  selector: 'app-placeorder',
  templateUrl: './placeorder.component.html',
  styleUrls: ['./placeorder.component.css'],
  providers:[PlaceorderService]
}) 
 
export class PlaceorderComponent implements OnInit {

 /*  @ViewChild(ProfileComponent ,{static:true}) profile;
  */
  closeResult = '';
  billingform:FormGroup;
  changeaddress : FormGroup;
  
  defaddress;
  fname;
  cname;
  address;
  city;
  pincode;
  mobno;
  email;
  grandtotal;
  discount;
  subtotal;
  deliverycharge;
  defaddressid="";
  orderpaymentmethod;
  placeorderfinal;
  aid;
  username:string;
  phoneno;
  rate;
  TransactionId :any;
  alladdress;
  //alladd;
  alladd : string[] = [];
  addName: any;
  test: { name: number[]; };
  changedefaid: any;
  addid: any;
  orderid: any;
  customerida;
  FirstName;
  CompanyName;
  Address;
  City;
  Pincode; 
  Mobileno;
  Email;
  Updateaid;
  addadress;
  //allAdd[] = new Array();
  //obj addressObject
  loading;
  addaddressform:FormGroup;
  state;
  country;
  State: any;
  Country: any;
  couponcode:string = '';;
  /* msg = 'parent to child placeorder'; */
  @ViewChild('addadress', { static: true }) addadressmodal: TemplateRef<any>;
  CourierId: any;

  
  /* @ViewChild('addadress') addadressmodal; */
  constructor(public modalService: NgbModal,public placeorderservice:PlaceorderService ,public  formbuilder:FormBuilder,public router:Router) {
    /* this.placeorderservice.datatest.subscribe(res=>{
      this.setFormStateaddaddress =res;
      this.updateaddress =res;
      this.onSubmitaddaddress =res;
      this.addressform =res;
      



    }) */
   }

  open(content) {
    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  public getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  /* openmodal(content) {
    this.modalService.open(content);
  } */
  
  ngOnInit(): void {

   /*  this.modalService.open(this.addadressmodal) */
    
     /* let vc = this.profile.setFormState();
     alert(vc) */
     
    this.setFormState();
    this.setFormchangeaddress();
    this.getdefaddress();
    this.setFormStateaddaddress();

    var customerid = sessionStorage.getItem('CustomerId');
    this.customerida = sessionStorage.getItem('CustomerId');
    this.placeorderservice.getcarttotal(customerid).subscribe((data)=>{
      //this.carttotal = data["Data"];
      //console.log(data["Data"][0]["SubTotal"]);

      this.subtotal = data["Data"][0]["SubTotal"];
      this.deliverycharge = data["Data"][0]["Deliverycharge"];
      this.discount = data["Data"][0]["Save"];
      this.grandtotal = data["Data"][0]["Total"] ;
      console.log(this.grandtotal)
      this.rate=this.grandtotal;
      this.CourierId = data["Data"][0]["CourierId"];
     // alert("Hii")
     // alert(this.CourierId)
    })

    console.log("outside the function  :"+this.grandtotal)

    //get session value
    this.username= sessionStorage.getItem('CustomerName');
    console.log(this.username)
    this.phoneno= sessionStorage.getItem('CustomerPhoneNo');
    console.log(this.phoneno)


    //get address 03/08/2021
    var customerid = sessionStorage.getItem('CustomerId');
    this.placeorderservice.getAddress(customerid).subscribe((data)=>{
      this.alladdress = data["Data"];
      console.log(this.alladdress);
      //this.cname = data["Data"][0]["AddressForName"];
      for(let i=0;i<this.alladdress.length;i++){
       // this.cname = data["Data"][i]["AddressForName"];
        this.addName = data["Data"][i]["AddressName"];
        this.addid = data["Data"][i]["AddressId"];
       // this.changedefaid = data["Data"][i]["AddressId"];
        //this.alladd[i]=("asd"=>"asd" );
        //this.cname;
        //this.alladd[i]["add"]=this.addName;
        
        
         this.alladd.push(this.addid);
       
      // console.log("Name :"+this.cname)
       //this.alladdress = data["Data"][i];

       // this.alladdressint.push()
       //this.alladdressint.push({})

      // let getall =({
      //   name : this.cname,
      //  // Address : this.addName
      //   //add1 : this.addName
      // });
      // let objectKeys = Object.keys;
      // let items = { keyOne: 'value 1', keyTwo: 'value 2', keyThree: 'value 3' };
      // this.test=getall;
      //alert(getall.name)
      }
      //console.log("Hello");
     // console.log(this.alladd);
      
     // allAdd c = []onsole.log(this.alladd)


     
    })

   /*  this.open(); */
 /*  // this.open(this.addadress);
  @ViewChild('addadress', { static: false }) private addadress;
  constructor(private modalService: NgbModal) { }

this.modalService.open(this.addadress); */
//this.open(this.content);


//If address not add then addaddress popup show on page load 
/* if(this.defaddressid == ''){
  this.open(this.addadressmodal);
} */


    
  }
  // Couponcodeapply(){
  //   // alert(this.couponcode)
  //   this.placeorderservice.getcarttotal( this.customerida).subscribe((data)=>{
  //     //this.carttotal = data["Data"];
  //     //console.log(data["Data"][0]["SubTotal"]);

  //     this.subtotal = data["Data"][0]["SubTotal"];
  //     this.deliverycharge = data["Data"][0]["Deliverycharge"];
  //     this.discount = data["Data"][0]["Save"];
  //     this.grandtotal = data["Data"][0]["Total"] ;
  //     console.log(this.grandtotal)
  //     this.rate=this.grandtotal;
  //     this.CourierId = data["Data"][0]["CourierId"];
  //    // alert("Hii")
  //    // alert(this.CourierId)
  //   })
  // }
 /*  ngOnChanges(changes: any) {
    open(this.addadress)
 } */
 /* test1(){
  this.open(this.addadressmodal)
 } */
//piyush start 06/09/2021
setFormStateaddaddress(): void{
    //console.log(this.defaddress)
    this.addaddressform = this.formbuilder.group({
      id:[''],
      fullname : ['',[Validators.required]],
      companyname : [''],
      address : ['',[Validators.required]],
      city : ['',[Validators.required]],
      pincode : ['',[Validators.required, Validators.minLength(6)]],
      mobileno : ['',[Validators.required,Validators.minLength(10), Validators.maxLength(12)]],
      /* email : ['',[Validators.required]], */
      state : ['',[Validators.required]],
      country : ['',[Validators.required]],
     /*  state : ['',[Validators.required]],
      country : ['',[Validators.required]] */

    })
  }
  get f() { return this.addaddressform.controls; }
  onSubmitaddaddress(){
  //  alert("hello")
    let addressform = this.addaddressform.value;
    this.addressform(addressform);
    //alert("Submit")
  }
  addressform(addressform : Customer){
    var customerid = sessionStorage.getItem('CustomerId');
    //alert(this.defaddressid)
    if(this.Updateaid !=null){
      this.placeorderservice.updateAddress(this.Updateaid,addressform).subscribe((res)=>{
        
       
        this.refresh();
     //  this.porderdefnull();
       
  
    })

    }
    else{
      if(this.addaddressform.value.companyname ==undefined)
      {
        //alert("hi");
        this.addaddressform.value.companyname = '';
      }
      else{
        //alert("heloi");
      }
      this.placeorderservice.postuser(customerid,addressform).subscribe((res)=>{
        let Message=res["Message"];
        let condition=res["IsSuccess"];
        let data=res["Data"];
  
        this.aid= res["Data"][0]["AddressId"];
        console.log(this.aid);
        this.refresh();
     //  this.porderdefnull();
       
  
    })
    }
    



    /* if(this.defaddressid == null){
      //console.log("samundar call")
      

    }
    else{
      //console.log("else"+this.aid);
   //   this.placeorder();

    } */
//end

  }
  updateaddress(addid){
   // alert(addid)
    var customerid = sessionStorage.getItem('CustomerId');
    /* this.placeorderservice.getdefaultaddress(customerid).subscribe((data)=>{
      this.defaddress = data["Data"];
     // this.FirstName = "asd;"
     // console.log(this.defaddress)
      //console.log(data["Data"][0]["AddressId"]);
      //get all data
     // if(this.defaddress !=''){
       this.Updateaid =addid;

      //this.defaddressid = data["Data"][0]["AddressId"];
      this.FirstName = data["Data"][0]["AddressForName"];
      this.CompanyName = data["Data"][0]["AddressCompanyName"];
      this.Address = data["Data"][0]["AddressName"];
      this.City = data["Data"][0]["AddressCityName"];
      this.Pincode = data["Data"][0]["AddressPincode"];
      this.Mobileno = data["Data"][0]["AddressPhoneNo"];
      this.Email = data["Data"][0]["AddressEmailId"];
      //console.log(this.fname)
     
    //  }
      

    })  */
    this.placeorderservice.getAddressByAddressId(addid).subscribe((data)=>{
      this.defaddress = data["Data"];
      // this.FirstName = "asd;"
      // console.log(this.defaddress)
       //console.log(data["Data"][0]["AddressId"]);
       //get all data
      // if(this.defaddress !=''){
        this.Updateaid =addid;
 
       //this.defaddressid = data["Data"][0]["AddressId"];
       this.FirstName = data["Data"][0]["AddressForName"];
       this.CompanyName = data["Data"][0]["AddressCompanyName"];
       this.Address = data["Data"][0]["AddressName"];
       this.City = data["Data"][0]["AddressCityName"];
       this.Pincode = data["Data"][0]["AddressPincode"];
       this.Mobileno = data["Data"][0]["AddressPhoneNo"];
       this.Email = data["Data"][0]["AddressEmailId"];
       this.State = data["Data"][0]["AddressState"];
       this.Country = data["Data"][0]["AddressCountry"];
       //console.log(this.fname)
    })
    
  }

  deleteaddress(addid){
    if(confirm("Are you sure to delete address")) {
      console.log("Implement delete functionality here");
      this.placeorderservice.deleteAddress(addid).subscribe((data)=>{
        //alert("delete sucessfully")
        
        this.refresh();
      
      })

    }
    
  }

  getdefaddress(){
    var customerid = sessionStorage.getItem('CustomerId');
    this.placeorderservice.getdefaultaddress(customerid).subscribe((data)=>{
      this.defaddress = data["Data"];
    //  this.FirstName = data["Data"][0]["AddressForName"];
     // console.log(this.defaddress)
      //console.log(data["Data"][0]["AddressId"]);
      //get all data
      if(this.defaddress !=''){
      this.defaddressid = data["Data"][0]["AddressId"];
      this.fname = data["Data"][0]["AddressForName"];
      this.cname = data["Data"][0]["AddressCompanyName"];
      this.address = data["Data"][0]["AddressName"];
      this.city = data["Data"][0]["AddressCityName"];
      this.pincode = data["Data"][0]["AddressPincode"];
      this.mobno = data["Data"][0]["AddressPhoneNo"];
      this.email = data["Data"][0]["AddressEmailId"];
      this.state = data["Data"][0]["AddressState"];
      this.country = data["Data"][0]["AddressCountry"];
      
      //console.log(this.fname)
     
      }
      else{
       // alert("hello")
        this.open(this.addadressmodal);
      }
      

    }) 
   // this.billingform.disable();
   // this.billingform.controls['this.fname'].disable();
    //alert(this.defaddressid)
  }
  changedefaddress(addressid){
    //alert(addressid)
    
    var customerid = sessionStorage.getItem('CustomerId');
    this.placeorderservice.updateDefaultAddress(customerid,addressid).subscribe((data)=>{
      this.defaddress = data["Data"];
      this.getdefaddress();
      /* this.loading = true; */
     // this.billingform.disable();
    })
    
  }
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
  setFormchangeaddress(): void{
    //console.log(this.defaddress)
    this.changeaddress = this.formbuilder.group({
      alladdress :['']
     /* name : [this.fname,[Validators.required]],
       //companyname : [this.cname,[Validators.required]],
      address : [this.address,[Validators.required]],
      password : [this.password,[Validators.required]],
     // city : [this.city,[Validators.required]],
      //pincode : [this.pincode,[Validators.required]],
      mobileno : [this.mobno,[Validators.required,Validators.minLength(10), Validators.maxLength(12)]],
      //email : [this.email,[Validators.required]] */

    })
  }

  setFormState(): void{
    console.log("hello")
    //console.log(this.defaddress)
    this.billingform = this.formbuilder.group({
      id:[this.defaddressid],
      fullname : [this.fname],
      companyname : [this.cname],
      address : [this.address],
      city : [this.city],
      pincode : [this.pincode],
      mobileno : [this.mobno],
      email : [this.email],
      state : [this.state],
      country : [this.country],
    })
   // console.log("In set form"+this.fname)
  }


  onSubmit(){
    let billform = this.billingform.value;
    this.billform(billform);
    //alert("Submit")
  }
  billform(billform : Customer){
    var customerid = sessionStorage.getItem('CustomerId');
    //alert(this.defaddressid)
    if(this.defaddressid == null){
      //console.log("samundar call")
      this.placeorderservice.postuser(customerid,billform).subscribe((res)=>{
        let Message=res["Message"];
        let condition=res["IsSuccess"];
        let data=res["Data"];

        this.aid= res["Data"][0]["AddressId"];
        console.log(this.aid);

        this.porderdefnull();
       
        //this.placeorder();

    })

    }
    else{
      //console.log("else"+this.aid);
      this.placeorder();

    }

      


  }
porderdefnull()
{
 // console.log("aa :"+grandtotal)
 //this.defaddressid=null;
 // this.billingform.reset();
 //payment code start 
  if(this.orderpaymentmethod == "COD"){
   // alert("place order aid"+this.aid)
  // alert("address Id"+this.aid)
    console.log(this.aid)
  var customerid = sessionStorage.getItem('CustomerId');
  this.placeorderservice.finalplaceorder(this.aid,customerid,this.orderpaymentmethod).subscribe((data)=>{
  this.placeorderfinal = data["Data"];
  console.log(this.placeorderfinal);
 // alert("Place order Sucessfully");
   // this.router.navigate(['/home']);
   this.showsweetalert();
  })
  }
  else if(this.orderpaymentmethod == "online"){
   // console.log(this.options)
   
    let optionsFinal=this.getRate(this.grandtotal*100,this.orderid);
    //alert(optionsFinal);
    let rzp1 = new this.placeorderservice.nativeWindow.Razorpay(optionsFinal);
    rzp1.open();
    //this.gettid();
   // alert(this.TransactionId);
  }
  else{
    alert("Please select any payment method")
  }
  //payment end
  //console.log(aid)
  
}
 placeorder(){
  // if(this.orderpaymentmethod == "COD"){
  //   var customerid = sessionStorage.getItem('CustomerId');
  // this.placeorderservice.finalplaceorder(this.defaddressid,customerid,this.orderpaymentmethod).subscribe((data)=>{
  //   this.placeorderfinal = data["Data"];
  //   console.log(this.placeorderfinal);
  //   //alert("Place order Sucessfully");
  //   //this.router.navigate(['/home']);
  //   this.showsweetalert();

  // })
  // }
  // else if(this.orderpaymentmethod == "online"){
  //   //console.log(this.options)
  //     //setvalue(9500);
  //    // console.log(this.options)
  //    let optionsFinal;
     
  //    /* var customerid = sessionStorage.getItem('CustomerId');
  //    this.placeorderservice.finalplaceorder(this.defaddressid,customerid,this.orderpaymentmethod).subscribe((data)=>{
  //      this.placeorderfinal = data["Data"];
  //      console.log("Online"+this.placeorderfinal);
  //      this.orderid = this.placeorderfinal;
       
  //      //alert("Place order Sucessfully");
  //      //this.router.navigate(['/home']);
  //     // this.showsweetalert();
   
  //    }) */
  //    //alert(this.orderid)
  //    optionsFinal=  this.getRate(this.grandtotal*100,this.orderid);
  //   // alert(this.orderid);
    
  //    //alert(optionsFinal);
  //   //alert(optionsFinal)
  //   //console.log(optionsFinal)
  //    let rzp1 = new this.placeorderservice.nativeWindow.Razorpay(optionsFinal);
  //    rzp1.open();

    
  //   // console.log(optionsFinal)
  //   //  alert(this.TransactionId);
  //   // console.log("Transcation id"+this.TransactionId)

  //   //  alert(this.rate);
  //   //let rzp1 = new this.placeorderservice.nativeWindow.Razorpay(this.options);
  // //  rzp1.open();
  // }
  // else{
  //   alert("Please select any payment method")
  // }


  //  alert(this.defaddressid)
  if(this.defaddressid == ''){
    alert("Please Add Address First")
  }
  else{
    let optionsFinal;
     optionsFinal=  this.getRate(this.grandtotal*100,this.orderid);
     let rzp1 = new this.placeorderservice.nativeWindow.Razorpay(optionsFinal);
     rzp1.open();
  }

     /* let optionsFinal;
     optionsFinal=  this.getRate(this.grandtotal*100,this.orderid);
     let rzp1 = new this.placeorderservice.nativeWindow.Razorpay(optionsFinal);
     rzp1.open(); */
     
  

  return this.orderid;
}
showsweetalert(){
  Swal.fire({
    title: 'Thanks',
    text: 'Place order Sucessfully',
    icon: 'success',
   // showCancelButton: true,
    confirmButtonText: 'Go to Home',
    confirmButtonColor: '#FD8E01',
    
  }).then((result) => {
    this.router.navigate(['/home']);
    
  })
}
  onItemChange(value){
   // console.log(" Value is : ", value );
    this.orderpaymentmethod = value;
 }
 resetform()
{
  this.defaddressid=null;
  this.addaddressform.reset();
  //this.billingform.enable();
  //this.fname.enable();

  //console.log("change address id"+this.defaddress)
}

getRate(rate,oid)
{
  let options = {
    //buyparts live api key : rzp_live_eRlcxzms391Pie
    "key": "rzp_live_eRlcxzms391Pie", // Enter the Key ID generated from the Dashboard  test mode key rzp_test_pSXjLcjx09zdZV             rzp_test_4plaJ49kG8kCei
    "amount":rate,  // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "Buy Parts Online",
    "description": "",
    "image": "https://example.com/your_logo",
    "order_id": oid,  
    "callback_url": `https://buypartsonline.in/AdminSide/Admin/Ajax/UpdateplaceOrderForBuyPartV2/${this.defaddressid}/${this.customerida}/online/${this.deliverycharge}/${this.CourierId}/0`,
    "redirect": true,
    // UpdateplaceOrderForBuyPartV2 olf api without courier id
     //This is a sample Order ID. Pass the `id` obtained in the response of Step 1  
    "handler": function (response){
        alert(response.razorpay_payment_id);
       // alert(response.razorpay_order_id);
        //alert(response.razorpay_signature)

      //this.TransactionId = response.razorpay_payment_id;
      
     
        /* if(response.razorpay_payment_id)
        {
         
        } */

        

    },
    "prefill": {
        "name": sessionStorage.getItem('CustomerName'),
        "email": "",
        "contact": sessionStorage.getItem('CustomerPhoneNo')
    },
    "notes": {
        "address": "Razorpay Corporate Office"
    },
    "theme": {
        "color": "#3399cc"
    }
  };
  return options;
}
//oline payment 29/07/2021

gettid(tid){
  alert("gettid function :");
}
refresh(): void {
  window.location.reload();
}



/* pay(){
  //console.log(this.options)
 
} */
 
}
