import { Component, OnInit } from '@angular/core';
import { Global } from '../global-constant';
import { MyorderService } from './myorder.service';
import Swal from 'sweetalert2';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { OrderdetailService } from '../orderdetail/orderdetail.service';
import { Router,NavigationExtras,NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-myorder',
  templateUrl: './myorder.component.html',
  styleUrls: ['./myorder.component.css']
})
export class MyorderComponent implements OnInit {

  orderhistory;
  orderno;
  orderpaymentmethod;
  orderqnt;
  ordertotal;
  orderdate;
  orderstatus;
  msg: boolean;
  page:number =1;
  isDisable=false;
  totallength: any;
  closeResult: string;
  orderdetails: any;
  otherdeatils: any;
  oid: any;
  cancelorderid: any;
  bill: any;
  returnpercentage: any;
  constructor(public datepipe: DatePipe,private api:MyorderService,private modalService: NgbModal,private api1:OrderdetailService, private router:Router) { }

  open(content) {
    this.modalService.open(content , {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    var customerid = sessionStorage.getItem('CustomerId');

    this.api.getorderhistory(customerid).subscribe((data)=>{
      this.orderhistory = data["Data"];
      for(let i = 0; i<=this.orderhistory.length ; i++)
      {
        let deliverydate = data["Data"][i]["OrderDeliveryDate"];
        //alert(deliverydate)
        let newdate = new Date(deliverydate);
        newdate.setDate(newdate.getDate() + 5);
        let newdatestring = this.datepipe.transform(newdate, 'yyyy-MM-dd');
       // alert("new date:"+newdatestring)
        let date= new Date();
        let current_date =this.datepipe.transform(date, 'yyyy-MM-dd');
      //  let current_date = new Date(today);
       // alert(current_date)
      //  alert(typeof(current_date))
        var d1 = Date.parse(current_date);
        var d2 = Date.parse(newdatestring); 
      //  alert(current_date)
     //  alert(newdatestring)
        if (d1 > d2) {
        //   alert("true")
           data["Data"][i]["ReturnStatus"] = "true";
        }
        else{
        //  alert("false")
         data["Data"][i]["ReturnStatus"] = "false";
        }
     //   break;
      }
      this.totallength =this.orderhistory.length;
      if(this.orderhistory == ''){
       
        this.msg = true;

      }
    //  console.log(this.orderhistory)
      //this.fname = data["Data"][0]["CustomerName"];
     // this.address = data["Data"][0]["CustomerAddress"];
     // this.mobno = data["Data"][0]["CustomerPhoneNo"];
        this.orderno = data["Data"][0]["OrderId"];
        //console.log(this.orderpaymentmethod)
        this.orderpaymentmethod = data["Data"][0]["OrderPaymentMethod"];
        this.orderdate =  data["Data"][0]["OrderDate"];
        this.orderqnt =  data["Data"][0]["OrderTotalQty"];
        this.ordertotal =  data["Data"][0]["OrderTotal"];
        this.orderstatus =  data["Data"][0]["OrderStageDropDown"];
       // this.shippingcharge = data["Data"][0]
       // alert(data["Data"][0]["OrderDeliveryDate"])
       
        // alert(deliverydate)
       /*  currentDate.setDate(currentDate.getDate() + 1);
        alert(currentDate) */
       /* const now = Date.now();
       alert(now) */
       /* let latest_date =deliverydate.transform(this.date, 'yyyy-MM-dd'); */
      // this.date=new Date();
        /* let latest_date =this.datepipe.transform(newdate, 'yyyy-MM-dd');
        alert(latest_date) */

          //let date= new Date();
          /* let diff = 5; //1 to increment and -1 to decrement
          deliverydate.setDate(deliverydate.getDate() + diff);
          alert(deliverydate) */
        // if(this.orderstatus =="Dispatched"){
          
        //   let date= new Date();
        //   alert(date)
        //   let diff = 5; //1 to increment and -1 to decrement
        //   date.setDate(date.getDate() + diff);
        // //  alert(date)
        //   let latest_date =this.datepipe.transform(date, 'yyyy-MM-dd');
        //   alert(latest_date)
          /* let date= new Date();
          let diff = 5; //1 to increment and -1 to decrement
          deliverydate.setDate(deliverydate.getDate() + diff);
          alert(deliverydate) */
         // this.isDisable =false;
         /* for(let i=0;i<this.orderhistory.length;i++){
           let OrderStage = data["Data"][i]["OrderStageDropDown"];
           //alert(OrderStage)
           if(OrderStage =="Dispatched"){
         //   alert("IF")
           //  this.isDisable  =false;
            
           }
           else{
          //   alert("ELSE")
            // this.isDisable =true;
           }
          
        //  }
        } */
        
    })
    
  }

  cancelorder(e){
   // alert(e)
   this.cancelorderid =e ;
   
    /* this.api.cancelOrder(e).subscribe((res)=>{
      let data = res["Data"];
     // alert(data)
     if(data ==1){
     // this.isDisable = false;
      this.showsweetalert();
     }
     
    }) */
    this.showsweetalertcancelconfirm();

  }
  showsweetalertcancelconfirm(){

    Swal.fire({
      title: 'Thanks',
      text: 'Are you sure to cancel order ??',
      icon: 'success',
      //showCloseButton:true,
      showCloseButton:true,
      showCancelButton: true,
      cancelButtonText: 'NO',
      confirmButtonText: 'YES',
      confirmButtonColor: '#FD8E01',
      cancelButtonColor: '#FD8E01',
      
    }).then((result) => {
      if (result.isConfirmed) {
        var customerid = sessionStorage.getItem('CustomerId');
        this.api.cancelOrder(this.cancelorderid,customerid).subscribe((res)=>{
      let data = res["Data"];
     // alert(data)
     if(data ==1){
     // this.isDisable = false;
      this.showsweetalert();
     }
     
    })
       // this.router.navigate(['/cart']);
     // this.refresh();
      } else if (result.dismiss === Swal.DismissReason.close) {
        
      }
      
    })
  }

  returnorder_myorder(e){
    this.oid = e;
    this.api.getSetting().subscribe((res)=>{
      this.returnpercentage = res["Data"][0]["SettingReturnPercentage"];
      sessionStorage.setItem('DynamicPercentage',this.returnpercentage);
     // alert(this.returnpercentage)
    })
  }


  returnorder(){
    //this.refresh();
    /* this.router.navigate(['/returnorder',this.orderno]); */
    this.router.navigate(['/returnorder',this.oid]).then(() => {
      window.location.reload();
    });

    /* for(let i=0;i<this.orderhistory.length;i++){
           let OrderStage = data["Data"][i]["OrderStageDropDown"];
           //alert(OrderStage)
           if(OrderStage =="Dispatched"){
         //   alert("IF")
           //  this.isDisable  =false;
            
           }
           else{
          //   alert("ELSE")
            // this.isDisable =true;
           }
          
        //  }
        } */
  }

  ordertdetaildata(oid){

    this.api1.getorderdetails(oid).subscribe(
      data => {
        this.orderdetails = data["Data"][0]["Orders"];
        this.otherdeatils = data["Data"][0]["OtherDetail"];
      //  console.log(this.otherdeatils);
  });
  }
  
  showsweetalertreturn(){
    Swal.fire({
      title: 'Thanks',
      text: 'Your Return Request Sucessfully Send',
      icon: 'success',
      //showCloseButton:true,
      showCloseButton:true,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#FD8E01',
      
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/returnorder']);
        
     // this.refresh();
      } else if (result.dismiss === Swal.DismissReason.close) {
        
      }
      
    })
  }
  onsubmitprint(OrderId){
   // alert("Hii")
    /* this.api.printBillWebsite("100").subscribe((res:(any))=>{
      this.bill = res["Data"];
      console.log(res)
    }) */
    this.router.navigate(['/billprint',OrderId]);
    

  }
  onsubmitreturnbill(OrderId){
     this.router.navigate(['/returnbill',OrderId]);
   }


  showsweetalert(){
    Swal.fire({
      title: 'Thanks',
      text: 'Your Order Cancel Sucessfully',
      icon: 'success',
      //showCloseButton:true,
      showCloseButton:true,
     // showCancelButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#FD8E01',
      
    }).then((result) => {
      if (result.isConfirmed) {
       // this.router.navigate(['/cart']);
      this.refresh();
      } else if (result.dismiss === Swal.DismissReason.close) {
        
      }
      
    })
  }
  refresh(): void {
    window.location.reload();
}

  

}
