import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global-constant';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  baseurl =Global.baseurl;
  constructor(private http:HttpClient) { }

  getcategory(){
   return this.http.get(`${this.baseurl}/getAllCategoryData`,{responseType:"json"})
  }

  cartDetails(CustomerId: string){

    var addtocart: any = new FormData();
    addtocart.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/getCart`,addtocart);

  }

  removecart(CartId: string){

    var rcart: any = new FormData();
    rcart.append("CartId", CartId);
    return this.http.post(`${this.baseurl}/removeCart`,rcart);

  }

  getcarttotal(CustomerId: string){

    var cid: any = new FormData();
    cid.append("CustomerId", CustomerId);
    return this.http.post(`${this.baseurl}/getCartTotalWithoutDeliveryCharge`,cid);

  }
  // getcarttotal(CustomerId: string,CouponcodeName:string){

  //   var carttotal: any = new FormData();
  //   carttotal.append("CustomerId", CustomerId);
  //   carttotal.append("CouponcodeName", CouponcodeName);
  //   // return this.http.post(`${this.baseurl}/getCartTotalv2`,carttotal);
  //   return this.http.post(`${this.baseurl}/getCartTotalv3`,carttotal);

  // }
  updatecartqnt(CartId:string,CartQuantity:string){
    var uqnt: any = new FormData();
    uqnt.append("CartId", CartId);
    uqnt.append("CartQuantity", CartQuantity);
    return this.http.post(`${this.baseurl}/updateCartQty`,uqnt);
  }
}
