/* import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

//import { BrandsComponent } from './brands.component';
//import { BrandscatlogComponent } from './brandscatlog.component';
import { CatlogComponent } from './catlog.component';
import { BrandscatlogModule } from '../brandscatlog/brandscatlog.module';
@NgModule({
  declarations: [CatlogComponent],
  exports:[ CatlogComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    BrandscatlogModule
  ],
  
})
export class CatlogModule { }
 */



import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

//import { HomeComponent } from './home.component';
/* import {CategoriesComponent} from '../categories/categories.component'; */
import { CatlogComponent } from './catlog.component';
import { ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import { SectionsModule } from '../sections/sections.module';
import {CategoriesModule} from '../categories/categories.module';
import { SearchcategoryModule } from '../searchbycategory/searchcategory.module';
 import { OffersModule } from '../offers/offers.module';
import { BrandsModule } from '../brands/brands.module';
@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        SectionsModule,
        NgbModule,
         CategoriesModule ,
         SearchcategoryModule,
         OffersModule ,
         BrandsModule,
         NgxPaginationModule,
         NgxSliderModule,
         ReactiveFormsModule
         
         
    ],
    declarations: [ CatlogComponent ],
    exports:[ CatlogComponent ],
    providers: [],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CatlogModule { }

